import type { PaginationList, PaginationRequest } from '@shared/util/request'
import { getPartner } from '@common/router/index'
import type { MarketInfoType } from '@common/types/marketInfo'
import type { FilterItem } from '@common/constants/filter'
import { ApiOptions, getRequest, postRequest } from '@common/api/index'

interface ClassSearchRequest extends PaginationRequest {
  name?: string
  trainingType?: number
  classType?: number
  popularity?: boolean
  hottest?: boolean
  sortType?: number
  asc?: boolean
}
interface TeacherResponse {
  id: number
  name: string
  avatar: string
}
interface ClassOption {
  id: number
  name: string
  originPrice: number
  promotionPrice: number
}
interface ClassSearchData {
  productId: string
  courseType: number
  categoryClassId: number
  trainingType: number
  classType: number
  trainingTypeDesc: string
  lessonCount: number
  schoolId: number
  organizationId: number
  name: string
  coverImage: string
  status: number
  originPrice: number
  promotionPrice: number
  beginTime: number
  endTime: number
  introductionType: number
  totalPurchaseNum: number
  teachers: TeacherResponse[]
  optionals: ClassOption[]
}
export const getClassSearch = (args: ClassSearchRequest) => {
  const { partnerId } = getPartner()
  return getRequest<PaginationList<ClassSearchData>>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/Class/Search',
      args: {
        organizationId: partnerId,
        ...args,
      },
    }),
  )
}

export const getPartnerSetting = (settingType: { settingType: number }) => {
  const { partnerId } = getPartner()
  return getRequest<number>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/GetPartnerSettingValue`,
      args: { ...settingType },
    }),
  )
}

export const getMarketInfo = () => {
  const { partnerId } = getPartner()
  return getRequest<MarketInfoType[]>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Site/GetMarketingInfo`,
    }),
  )
}

interface WxShareRequest {
  organizationId: number
  url: string
  type: string
  contentId?: number | null
}
interface WxShareResponse {
  appId: string
  timestamp: string
  nonceStr: string
  signature: string
  title: string
  subhead: string
  iconUrl: string
}
export const getWxShareInfo = ({ organizationId, url, contentId, type }: WxShareRequest) => {
  return getRequest<WxShareResponse>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/GetWxShareInfo',
      args: {
        organizationId,
        url: url || location.href,
        contentId,
        type,
      },
    }),
  )
}

interface SearchBaseRequest extends PaginationRequest {
  organizationType?: number | null
  trainingTypeId?: number | null
  classId?: number | null
}
interface ClassSearchBaseData {
  classId: number
  classCourseId: number
  teacherSchoolId: number
  name: string
  coverImage: string
  type: number
  courseType: number
  expiredTime: number
  lessonCount: number
  subjectName: string
  studyDuration: number
  courseOriginType: number
}

interface RecordSearchRequest extends SearchBaseRequest {
  typeList?: number[] | null
}
interface RecordSearchResponse extends ClassSearchBaseData {
  courseId: number
  type: number
  lastStudyTime: number
  lastStudyLessonName: string[]
  quizId: string
  sectionTypeId: string
  sectionTypeName: string
  questionCount: number
  totalQuestionCount: number
  isOnlineQuiz: boolean
  courseOriginType: number
}
export const getRecordSearch = (params: RecordSearchRequest) => {
  const { partnerId } = getPartner()
  return getRequest<PaginationList<RecordSearchResponse>>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Mall/StudyRecord/Search`,
      args: params,
    }),
  )
}

interface StudentClassSubjectFilterRequest {
  classId: number
  type: number
  organizationType?: number
  returnCourseWithoutSubject?: boolean
}
interface StudentClassSubjectFilterResponse {
  yearFilters: FilterItem[]
  subjectFilters: FilterItem[]
}
export const getStudentClassSubjectFilter = (params: StudentClassSubjectFilterRequest) => {
  const { partnerId } = getPartner()
  return getRequest<StudentClassSubjectFilterResponse>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Mall/GetClassSubjectFilter`,
      args: { ...params, organizationId: partnerId },
    }),
  )
}

interface LiveNotStartResponse {
  id: number
  outerClassId: number
  teacherSchoolId: number
  name: string
  coverImage: string
  expiredTime: number
  liveName: string
  liveStartTime: number
  liveStatus: number
}
export const getLiveNotStart = (params: PaginationRequest) => {
  const { partnerId } = getPartner()
  return getRequest<PaginationList<LiveNotStartResponse>>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Mall/Live/SearchNotEnd`,
      args: params,
    }),
  )
}

interface ClassTypeRequest {
  trainingTypeId: number
  isShow?: boolean
}
interface ClassTypeResponse {
  classTypeId: number
  trainingTypeId: number
  name: string
  isShow: boolean
}
export const getClassTypeList = (params: ClassTypeRequest) => {
  const { partnerId } = getPartner()
  return getRequest<ClassTypeResponse[]>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/ClassType/List',
      args: { ...params, organizationId: partnerId },
    }),
  )
}

interface ClassTypeUpdateItem {
  trainingTypeId: number
  classTypeId: number
  isShow: boolean
}
interface ClassTypeUpdateRequest {
  operateType: number
  items: ClassTypeUpdateItem[]
}

export const updateClassType = (params: ClassTypeUpdateRequest) => {
  const { partnerId } = getPartner()
  return postRequest(
    new ApiOptions({
      url: `/net/course/${partnerId}/Mall/ClassType/Update`,
      args: params,
    }),
  )
}

export interface CustomAppDownloadInfo {
  logo: string
  androidAppDownloadUrl: string
  androidAppScheme: string
  canDownloadIOSApp: boolean
}

export const getCustomAppDownloadInfo = (partnerId?: number | string) => {
  return getRequest<CustomAppDownloadInfo>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/CustomAppDownload/Info',
      args: { organizationId: partnerId ?? getPartner().partnerId },
    }),
  )
}

export const getCustomAppIOSDownloadUrl = (partnerId?: number | string) => {
  return getRequest<string>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/CustomAppDownload/IOSDownloadUrl',
      args: { organizationId: partnerId ?? getPartner().partnerId },
    }),
  )
}

export interface EntryItem {
  name: string
  moduleId: number
  iconUrl: string
}
export const getQuickEntrySetting = () => {
  const { partnerId } = getPartner()
  return getRequest<EntryItem[]>(
    new ApiOptions({
      url: `/net/course/${partnerId}/QuickEntry/Setting`,
    }),
  )
}

export interface EntryGroup {
  name: string
  entryList: EntryItem[]
}
export const getAllQuickEntry = () => {
  const { partnerId } = getPartner()
  return getRequest<EntryGroup[]>(
    new ApiOptions({
      url: `/net/course/${partnerId}/QuickEntry/List`,
    }),
  )
}

export const deleteQuickEntry = (params: { moduleId: number }) => {
  const { partnerId } = getPartner()
  return postRequest(
    new ApiOptions({
      url: `/net/course/${partnerId}/QuickEntry/Delete`,
      args: { ...params },
    }),
  )
}

export const updateQuickEntry = (params: { moduleIds: number[] }) => {
  const { partnerId } = getPartner()
  return postRequest(
    new ApiOptions({
      url: `/net/course/${partnerId}/QuickEntry/Update`,
      args: { ...params },
    }),
  )
}

interface TodoQuickEntryItem {
  type: number
  title: string
  count: number
}
export interface TodoAuditItemRow {
  id: number
  displayName: string
  generatedTime: number
}
export interface TodoAuditItem {
  type: number
  title: string
  rows: TodoAuditItemRow[]
}
export interface TodoDetail {
  quickEntryList: TodoQuickEntryItem[]
  auditList: TodoAuditItem[]
}
export const getIndexTodoList = () => {
  const { partnerId } = getPartner()
  return getRequest<TodoDetail>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Todo/List`,
    }),
  )
}

export const ignoreIndexTodo = (params: { id: number; type: number }) => {
  const { partnerId } = getPartner()
  return postRequest(
    new ApiOptions({
      url: `/net/course/${partnerId}/Todo/Ignore`,
      args: { ...params },
    }),
  )
}

export interface MarketingMaterialItem {
  name: string
  url: string
  fileSuffix: string
}
export const getMarketingMaterialShareInfo = (params: { shareId: number }) => {
  return getRequest<MarketingMaterialItem[]>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/Marketing/Material/Share/Info',
      args: { ...params },
    }),
  )
}
